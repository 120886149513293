import classes from "./Profile.module.css";
import image from "../Components/Images/tree.png"

const Profile = () =>{
    return(
        <div className={classes.container}>
            <img className={classes.stle} src={image} alt="" />

        </div>
    );
};
export default Profile;