import Header from "../Header";
import Navbar from "../Navbar";
import classes from "./Service/GenralInsurance.module.css";
import image from "./Service/asset/general-insuarance.jpg";
import Footer from "../Footer";
import { useState,useEffect } from 'react';
import "../Service/Service/Acc.css";
import icon from './Service/asset/next.png';


function GenralInsurance(){
    const [r1, setR1] = useState(true);
    const [r2, setR2] = useState(false);
    const [r3, setR3] = useState(false);
    const [r4, setR4] = useState(false);
    const [r5, setR5] = useState(false);

    const clkHnd1 = () => {
        setR1(true);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd2 = () => {
        setR1(false);
        setR2(true);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd3 = () => {
        setR1(false);
        setR2(false);
        setR3(true);
        setR4(false);
        setR5(false);
    };

    const clkHnd4 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(true);
        setR5(false);
    };

    const clkHnd5 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(true);
    };

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return(
        <div className={classes.maim}>
            <Header/>
            <Navbar/>
            <div className={classes.bg}>
                <h1>General Insurance</h1>
            </div>
            <div className={classes.container}>
                <div className={classes.left}>
                    <h1>Introduction</h1>
                    <p>There are different types of General Insurance Policies offered by Unity Finsolution as below:</p>
                    <p> <i class="fa-regular fa-hand-point-right"></i> Property Insurance</p>
                    <p><i class="fa-regular fa-hand-point-right"></i> Health Insurance</p>
                </div>
                <div className={classes.right}>
                    <img src={image} alt=""></img>
                </div>
            </div>
            <div className='AccHold'>
            <div className='left'>
                <button className={r1 ? 'AccBtn1 AccBtn11' : 'AccBtn'} onClick={clkHnd1}>Property Insurance</button>
                <button className={r2 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd2}>Types of Property Insurance:</button>
                <button className={r3 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd3}>Health Insurance</button>
                
            </div>
            <div className='right'>

                {r1 && <div className='right1'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Property Insurance refers to a wide category of general insurance. Here are some of the reasons as to why you should invest in a comprehensive Property Insurance.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>A lot of business depends on the machinery that they have. In case the machinery is lost, stolen or repaired, the property insurance will compensate the amount as it is mentioned in the policy.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>According to the insurance dictionary, the term “Property” refers to machinery, stocks, buildings, fixtures, furniture’s and equipment’s. Property Insurance will cover the specified property against burglary risks, fire & allied perils.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Consider the case of stocks. A large amount of goods and stocks are sent everyday through sea, air, land and rail. The business will incur a great deal of loss if the stocks are damaged or lost in transit. It becomes very important to consider your stocks are property and take a Property Insurance accordingly.</p>
                    </div>
                    
                </div>}

                {r2 && <div className='right2'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'><b>Fire Insurance :</b>  
 &nbsp;A fire insurance policy will offer protection for manufacturing units, buildings, accessories, goods including raw material, finished goods, machinery and equipment’s against fire and allied perils.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'> <b>Burglary Insurance :</b>   
 &nbsp;A burglary insurance policy may be offered for a house or for a business unit. This policy covers assets like cash, securities and valuables kept inside the property and other stocks inside the property premises.</p>
                    </div>
                    
                </div>}

                {r3 && <div className='right3'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Health insurance policy is an insurance scheme that provides its customer with financial cover against the medical cost for any individual or family.</p>
                    </div>
                    
                </div>}

                

                
            </div>
        </div>
            
      <Footer/>
            
        </div>

    );
};
export default GenralInsurance;