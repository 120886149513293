import Footer from "../../Footer";
import Header from "../../Header";
import Navbar from "../../Navbar";
import classes from "./Doctor.module.css";
import image from "../../../Components/Images/photo-automobile-production-line-welding-car-body-modern-car-assembly-plant-auto-industry.jpg";
import { useEffect } from "react";

function Machinary(){
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return(
        <div className={classes.container1}>
            <Header/>
            <Navbar/>
            <div className={classes.bg}>
                <h1>Machinery Loans</h1>
            </div>
        <div className={classes.container}>
            <div className={classes.left}>
                <h1>Introduction</h1>
                <p> Unlock Your Business Potential with Unity Finsolution Machinery Loans! 
                Is your business poised for growth, but you lack the necessary machinery to take it to the next level? Look no further! Unity Finsolution is here to fuel your ambitions with our specialized Machinery Loans – designed to empower businesses like yours and drive success through cutting-edge equipment.
                </p>
<div className={classes.key}>
    <h4>🛠️ Why Choose Unity Finsolution Machinery Loans?</h4>
    <p><i class="fa-regular fa-hand-point-right"></i><b>Tailored Financing: :</b> Our Machinery Loans are crafted to cater to the unique needs of your business, ensuring you get the right financial support to acquire the machinery required for optimal performance.</p>
    <p><i class="fa-regular fa-hand-point-right"></i><b>Compassionate Interest Rates:</b> Benefit from competitive interest rates that reflect our commitment to supporting the healthcare community.</p>
    <p><i class="fa-regular fa-hand-point-right"></i><b>Swift Approvals:</b> Your time is valuable. Experience quick and hassle-free loan approvals to keep your focus where it matters – on your patients.</p>
    
</div>
            </div>
            <div className={classes.right}>
                <img src={image} alt="" />
            </div>
        </div>
        <Footer/>
        </div>
    );
};
export default Machinary;