import classes from "./ChoicePartner.module.css";

import image1 from "./Partners/aditya-birla.png";
import image2 from "./Partners/axis-bank.png";
import image3 from "./Partners/idfcfirstbank.png";
import image4 from "./Partners/dbs.png";
import image5 from "./Partners/sbi.png";
import image6 from "./Partners/fedral-bank.png";
import image7 from "./Partners/bankofbaroda.png";
import image8 from "./Partners/Central_Bank_of_India_Logo.png";
import image9 from "./Partners/hdfc.png";
import image10 from "./Partners/icici.png";
import image11 from "./Partners/Pnb-Housing-Finance-Logo-Vector.png";
import image12 from "./Partners/indiabulls.png";
import image13 from "./Partners/IDBI-Bank-logo.png";
import image14 from "./Partners/kotak.png";
import image15 from "./Partners/Bank-of-India.png";
import image16 from "./Partners/piramal-house.png";
import image17 from "./Partners/rbl.png";
import image18 from "./Partners/shreeram_housing_finance.png";
import image19 from "./Partners/seb.png";
import image20 from "./Partners/tata.png";
import image21 from "./Partners/yes-bank.png";
import image22 from "./Partners/Au_finance.png";
import image23 from "./Partners/SMFG-Sumitomo-Mitsui-Financial-Group-900x0.png";
import image24 from "./Partners/bajaj-finance6835.jpg";
import image25 from "./Partners/Godrej_Capital_Logo.jpg";
import image26 from "./Partners/clix.png";
import image27 from "./Partners/deutsche-bank-logo.png";
import image28 from "./Partners/edelweiss.jpg";
import image29 from "./Partners/Hero_FinCorp_Logo_New_Final_2013_Vertical_Wiki.png";
import image30 from "./Partners/neogroeth.png";
import image31 from "./Partners/ambit.png";
import image32 from "./Partners/ungro.jpg";
import image33 from "./Partners/Unitylogo_1.jpg";
import image34 from "./Partners/l&t.png";
import image35 from "./Partners/CHOLAFIN.NS_BIG-b34c4f1d.png";
import image36 from "./Partners/Ind-card-1.jpg";
import image37 from "./Partners/credit-saison8042.logowik.com.webp";
import image38 from "./Partners/PF_Logo-Blue-770x399.webp";
import image39 from "./Partners/mas.jpg";
import image40 from "./Partners/IIFLWeb_0.webp";
import image41 from "./Partners/mintyfi.png";


function ChoicePartner(){
    return(
        <div className={classes.container}>
            <div className={classes.head}><h1>Choice of Partners With Us</h1></div>
            <div className={classes.linehlder}><span className={classes.divBar}></span></div>
            <div className={classes.row}>
                
                <img src={image23} alt="" ></img>
                <img src={image24} alt="" ></img>
                <img src={image25} alt="" ></img>
                <img src={image26} alt="" ></img>
                <img src={image27} alt="" ></img>
                <img src={image28} alt="" ></img>
                <img src={image29} alt="" ></img>
                <img src={image30} alt="" ></img>
                <img src={image31} alt="" ></img>
                <img src={image32} alt="" ></img>
                <img src={image33} alt="" ></img>
                <img src={image34} alt="" ></img>
                <img src={image35} alt="" ></img>
                <img src={image36} alt="" ></img>
                <img src={image37} alt="" ></img>
                <img src={image38} alt="" ></img>
                <img src={image39} alt="" ></img>
                <img src={image40} alt="" ></img>
                <img src={image41} alt="" ></img>
                <img src={image1} alt="" ></img>
                <img src={image2} alt="" ></img>
                <img src={image3} alt="" ></img>
                
                <img src={image5} alt="" ></img>
                <img src={image6} alt="" ></img>
                <img src={image7} alt="" ></img>
                <img src={image8} alt="" ></img>
                <img src={image9} alt="" ></img>
                <img src={image10} alt="" ></img>
                <img src={image11} alt="" ></img>
                <img src={image12} alt="" ></img>
                <img src={image13} alt="" ></img>
                <img src={image14} alt="" ></img>
                <img src={image15} alt="" ></img>
                <img src={image16} alt="" ></img>
                <img src={image17} alt="" ></img>
                <img src={image18} alt="" ></img>
                <img src={image19} alt="" ></img>
                <img src={image20} alt="" ></img>
                <img src={image21} alt="" ></img>
                <img src={image22} alt="" ></img>
                
            </div>
        </div>

    );
};
export default ChoicePartner;