import classes from "./Services.module.css";
import image1 from "./ImageServices/construction finance.png";
import image2 from "./ImageServices/property.png";
import image3 from "./ImageServices/building.png";
import image4 from "./ImageServices/assets.png";
import image5 from "./ImageServices/family.png";
import image6 from "./ImageServices/home.png";
import { Link } from "react-router-dom";

function Services (){
    return(
        <div className={classes.container}>
            <div className={classes.row}>
            <div className={classes.card}>
                <div className={classes.left}><img src={image1}/></div>
                <div className={classes.right}>
                    <h4>Construction Finance</h4>
                    <p>Unity Finsolution offers Construction Finance to Real Estate Developers for projects in select cities.</p>
                    <div className={classes.btn}><Link to="/constructionFinance">Read More</Link></div>
                </div>
                
            </div>
            <div className={classes.card}>
                <div className={classes.left}><img src={image2}/></div>
                <div className={classes.right}>
                    <h4>Loan Against Property</h4>
                    <p>Aspirations to continually grow/expand one's business are what define most businessmen.</p>
                    <div className={classes.btn}><Link to="/loanagainstproperty">Read More</Link></div>
                </div>
                
            </div>
            <div className={classes.card}>
                <div className={classes.left}><img src={image3}/></div>
                <div className={classes.right}>
                    <h4>Unsecured Business Loan</h4>
                    <p>Grow your small firm or large-scale company with business loans from HDFC Bank.</p>
                    <div className={classes.btn}><Link to="/unsecuredBuisnessLoan">Read More</Link></div>
                </div>
                </div>
                
            </div>
            <div className={classes.row}>
            <div className={classes.card}>
                <div className={classes.left}><img src={image4}/></div>
                <div className={classes.right}>
                    <h4>SME Loans</h4>
                    <p>At Unity Finsolution, we understand how important investment is to ensure that businesses reach their full potential.</p>
                    <div className={classes.btn}><Link to="/smeloan">Read More</Link></div>
                </div>
                
            </div>
            <div className={classes.card}>
                <div className={classes.left}><img src={image5}/></div>
                <div className={classes.right}>
                    <h4>Life Insurance</h4>
                    <p>Everyone knows why life insurance is important, but many of us choose to ignore the reality of what would happen if we were to die suddenly without the proper protection.</p>
                    <div className={classes.btn}><Link to="/lifeInsurance">Read More</Link></div>
                </div>
                
            </div>
            <div className={classes.card}>
                <div className={classes.left}><img src={image6}/></div>
                <div className={classes.right}>
                    <h4>Home Loan</h4>
                    <p>They say your home is where your story begins. At Unity Finsolution, we pride ourselves in helping you begin this story as conveniently as can be.</p>
                    <div className={classes.btn}><Link to="/homeLoan">Read More</Link></div>
                </div>
                </div>    
            </div>

        </div>
    );
};
export default Services;