import classes from "./Review.module.css";

function Review (){
    return(
       <div className={classes.container}>
        <div className={classes.card}>
            <div className={classes.image}><i class="fa-solid fa-users"></i></div>
            <div className={classes.heading}><p>Over 75000 satisfied customer</p></div>
        </div>
        <div className={classes.card}>
            <div className={classes.image}><i class="fa-solid fa-location-dot"></i></div>
            <div className={classes.heading}><p>50+ Locations Served By Us</p></div>
        </div>
        <div className={classes.card}>
            <div className={classes.image}><i class="fa-solid fa-building-columns"></i></div>
            <div className={classes.heading}><p>Offers From 25+ Partner Banks</p></div>
        </div>
        <div className={classes.card}>
            <div className={classes.image}><i class="fa-solid fa-star"></i></div>
            <div className={classes.heading}><p>Rated 4.5/5 By Customers</p></div>
        </div>
        <div className={classes.card}>
            <div className={classes.image}><i class="fa-solid fa-desktop"></i></div>
            <div className={classes.heading}><p>Online Application, Quick Processing</p></div>
        </div>
        <div className={classes.card}>
            <div className={classes.image}><i class="fa-sharp fa-solid fa-shield-halved"></i></div>
            <div className={classes.heading}><p>Transparent Terms & Conditions</p></div>
        </div>
        <div className={classes.card}>
            <div className={classes.image}><i class="fa-solid fa-user-tie"></i></div>
            <div className={classes.heading}><p>Dedicated Advisor + Doorstep Service</p></div>
        </div>
        <div className={classes.card}>
            <div className={classes.image}><i class="fa-solid fa-award"></i></div>
            <div className={classes.heading}><p>50+ Awards won by Unity</p></div>
        </div>
       </div>
    );
};
export default Review;