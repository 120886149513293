import { Link } from "react-router-dom";
import classes from "./BuisnessPartnertext.module.css";

function BuisnessPartnertext(){
    return(
       <div className={classes.container}>
        <div className={classes.head}><h1>Become a Business Partner</h1></div>
        <div className={classes.text}><p>Earning money was never this easy! Become a Business Partner with Unity Finsolution, today!</p></div>
        <div className={classes.btn}><button><Link to="/buisnesspartnerprogram" className={classes.btnlearn}>Know More</Link></button></div>
       </div>
    );
};
export default BuisnessPartnertext;