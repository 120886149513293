import classes from './Sme.module.css';
import sme from './asset/sme.jpg';
import Acc from './Acc';
import Header from '../../Header';
import Navbar from '../../Navbar';
import Footer from '../../Footer';
import { useEffect } from 'react';

const Sme = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return (
        <div className={classes.hold}>
            <Header/>
            <Navbar/>
            <div className={classes.headhold}>
                <p className={classes.head}>SME Loans</p>
            </div>

            
            <div className={classes.introhold}>
                <div className={classes.introL}>
                <p className={classes.intro}>Introduction</p>
                    <p className={classes.introLTxt}>This comes under unsecured business loan. This loan is similar to personal loan but it is used for business purpose. The interest and EMI is fixed. There are banks who gives small business loan also to start ups for their business.</p>
                    <p className={classes.introLTxt}>We at Unity Finsolution aim to uplift a small business enterprise by providing loan for their business capital. Any kind of SME like a small manufacturer, supplier, distributor as well as service provider can apply & avail a SME Loan.</p>
                    <p className={classes.introLTxt}>Infact, for an SME Loan, a lot of emphasis is on the business plan and business vision of the SME and not necessarily their background as well as financial history.</p>
                    <p className={classes.pur}>Purpose Of The SME Loan Product</p>
                    <p className={classes.pur2}>The SME Loan product is in place to facilitate the following objectives</p>

                    
<p className={classes.introLTxt}>- To improve the credit flow in the market in the SME segment</p>
                    

                    <div className={classes.pointsHold}>
                        <p className={classes.introLTxt}>- To make policies & norms in this sector so as to ensure that timely finance and funds are available to the SME's</p>
                    </div>

                    <p className={classes.pur2}>The disbursed funds from SME Loan can be used for any of the following needs</p>

                    
                        
                        <p className={classes.introLTxt}>- Working Capital Requirements</p>
                    
                    
                        <p className={classes.introLTxt}>- Purchase of inventory</p>
                    
                    
                        <p className={classes.introLTxt}>- Purchase of additional plant & machinery</p>
                   
                    
                        <p className={classes.introLTxt}>- Payments to debtors</p>
                    
                    <p className={classes.introLTxt}>- Look no further if you starting your business or you already have a  &nbsp; &nbsp; &nbsp;existing business and apply with UNITY</p>
                </div>
                <div className={classes.introR}>
                    <img src={sme} alt='' className={classes.sme}></img>

                </div>
            </div>

            <div>
                <Acc></Acc>
            </div>
            <Footer/>

        </div>
    )
};

export default Sme;