import classes from './AboutCo.module.css';
import a1 from './asset/about1.jpg';
import AdvCard from './AdvCard';
import clock from './asset/clock.png';
import bnk from './asset/bank.png';
import mul from './asset/team.png';
import per from './asset/100-percent.png';
import chk from './asset/check.png';
import { Link } from "react-router-dom"; 

import Card from './Card';

const About = () => {
    return (
        <div className={classes.hold}>
            <div className={classes.headhold}>About Us</div>

            
            <div className={classes.introhold}>

                <div className={classes.introL}>
                <h1 className={classes.introhead}>Introduction</h1>
                    <p>Unity Finsolution is a dynamic and dedicated professional’s team of experienced financial services. We are MUMBAI-Pune based Company we can offer Lowest EMI and Interest Rates. Unity Finsolution who set up a financial business consultancy Services starting from 2023 as Name was Unity Finsolution to facilitate the residential commercial borrowing requirements of high net worth individuals. Unity Finsolution is always trying to Resolve Financial Problem all real needy People as Individual as well as Businessmen.
                    Here we invite you for a safe, scheduled and sophisticated business loan in order to maintain a sustainable growth. We’re driven by information based lending, online application acceptance, best in industry interest rate and viability based financing.
                    </p>
                    <p>Unity Finsolution comes with a mission to ensure easy availability of unsecured Working Capital to small business owners in India. Our technology enabled platform allows us to offer credit in a fast and hassle free manner. Unity Finsolution possesses a diversified products portfolio: loans for individuals and entrepreneurs, MSME Loans, Hypothecation Loans, and small Micro Loans. We always believe that no dream should go unfulfilled because of the lacks of funds. After fulfilling such dreams, our customers trust has fuelled our own dream to become the largest retail finance company in the country.</p>
                    <p></p>
                    
                </div>
                <div className={classes.introR}>
                    <img className={classes.introImg} src={a1}></img>
                </div>
            </div>
             <div className={classes.ha}>
            <p className={classes.introhead2}>Advantages Of Dealing With Unity Finsolution</p>
            <p className={classes.introhead3}>Here Is An Overview To The Advantages Of Dealing With Unity Finsolution For Customers</p>
            <p className={classes.introhead4}>Our consistency in maintaining a strong rapport with Chartered Accountants, Builders, reputed Real Estate Agents, Human Resource Heads, HNls, Bankers, as also, with Business Partners and Referral Partners has seen us grow consistently. Our reputation for transparency, quality service and timely delivery is next to none - and we continuously work to better our processes to do even better.</p>
            </div>
            <div className={classes.bgimg}>
                <div className={classes.r}>
                <div className={classes.top }>
                    <Card head='Knowledge-Sharing' txt='Our sales team is clued in to the latest changes in products and services'></Card>
                    <Card head='One-Stop-Solution' txt="We ensure the solutions to the client' s needs are presented all the possibilities"></Card>
                    <Card head='Delivering Efficiency' txt='Our strong relationship with the lenders ensures our clients get Preferential treatment.'></Card>
                </div>

                <div className={classes.top}>
                    <Card head='Experienced Outlook' txt='Our sales team is clued in to the latest changes in products and services'></Card>
                    <Card head='Relationship Plus' txt='We have received Award for Top Performing channel partner from SCB, Centurian Bank of Punjab,'></Card>
                    <Card head='Product Portfolio' txt='Home Loans, Loans against Property, Commercial Purchases,'></Card>
                </div>
                </div>
            </div>

            <p className={classes.advHead}>Advantages of joining as Referral Partner</p>
            <div className={classes.adv}>

                <AdvCard image={clock} txt='Better & Timely Payout.'></AdvCard>
                <AdvCard image={bnk} txt="Solution From Various Private Sector Banks & NBFC' s"></AdvCard>
                <AdvCard image={mul} txt='Offering Of Multiple Products'></AdvCard>
                <AdvCard image={per} txt="100% Transparency Is Maintained Throughout The Transaction"></AdvCard>
                <AdvCard image={chk} txt="For Sole Selling Agents We Can Get Builder Project Approved From Multiple Banks & Nbfc's"></AdvCard>
            </div >

            <div className={classes.grow}>
                <p className={classes.growTxt}>Let's grow together!</p>
                <button className={classes.growBtn}><Link to="/contact">CONTACT US</Link></button>
            </div>
        </div >
    )
}

export default About;