import classes from "./EmiHome.module.css";
import image1 from "../Components/Images/UNITYLOGO.jpeg";
import image2 from "./Images/Rating_stars_4.5.svg.png";
import image3 from "./Images/assured-removebg-preview-removebg-preview.png";


function EmiHome(){
 //Dom Event handler
function hndclick(){
    //console.log(document.getElementsByName('txtData'));
    const amount = document.getElementsByName('txtData')[0].value;
    const duration = document.getElementsByName('txtData')[1].value;
    const roi = document.getElementsByName('txtData')[2].value;

   // console.log(amount,typeof  amount);
    //console.log(duration);
    //console.log(roi);

    var P=Number(amount);
    var N=Number(duration);
    var R=Number(roi);

    R=R/12/100;
    N = N*12;
    var EMI= P*R* ((1+R)**N) / ((1+R)**N-1);
    EMI=Math.round(EMI);
    console.log(EMI);

    var total_payable_amount=EMI*N;
    console.log(total_payable_amount);

    var interest=total_payable_amount-P;
    console.log(interest);


    document.getElementById('emi').innerHTML=EMI;
    document.getElementById('loan_amountShow').innerHTML=P;
    document.getElementById('interest').innerHTML=interest;
    document.getElementById('final_amount').innerHTML=total_payable_amount;

 }

    return(
       <div className={classes.container}>
        <div className={classes.left}>
            <h1>Home Loan EMI Calculator NOV 2023</h1>
            <p><i class="fa-solid fa-clock-rotate-left"></i>Last Updated 5th Nov 2023</p>
            
            <div className={classes.tble}>
                <p><i class="fa-regular fa-hand-point-right"></i>Loan EMI is calculated at 8.50% for illustration purpose.</p>
                <p><i class="fa-regular fa-hand-point-right"></i>Banks offering lowest EMI on home loan are Bank of Baroda, Axis Bank, Kotak Mahindra Bank, HDFC Bank and ICIC Bank.</p>

                <h3>Home Loan Calculator</h3>
                <p>Current repo rate stands at 6.5%</p>

                <table>
                    <tr>
                    <th>Loan Amount</th>
                    <th>20 year loan</th>
                    <th>25 year loan</th>
                    <th>30 year loan</th>		
                    </tr>
                    <tr>
                     <td>₹ 30 Lakh</td>
                     <td>₹  26,035</td>
                     <td>₹ 24,157</td>
                     <td>₹ 23,067</td>			
                    </tr>
                    <tr>
                     <td>₹ 50 Lakh</td>
                     <td>₹ 43,391</td>
                     <td>₹ 40,261</td>
                     <td>₹ 38,446</td>						
                    </tr>
                    <tr>
                     <td>₹ 1 crore</td>
                     <td>₹ 86,782</td>
                     <td>₹ 80,523</td>
                     <td>₹ 76,891</td>									
                    </tr>
                </table>
            </div>
        </div>
        <div className={classes.right}>
           <div className={classes.wrapper}>
            <h2>Loan EMI Calculator</h2>
            <span className={classes.divBar}></span>
            <div className={classes.calculator}>
            <div className={classes.calculatorInput}>
                <label for="">Loan Amount (Rs.):</label>
                <input type="number" id="loan_amount" name="txtData"></input>
            </div>
            <div className={classes.calculatorInput}>
                <label for="">Loan Tenure (years):</label>
                <input type="number" id="loan_tenure" name="txtData"></input>
            </div>
            <div className={classes.calculatorInput}>
                <label for="">Interest Rate(%):</label>
                <input type="number" id="loan_interest" name="txtData"></input>
            </div>
            <div className={classes.calculatorInput}>
                <button className={classes.calculatorBtn} id="btn" onClick={hndclick}>Calculate EMI</button>
            </div>
            </div>
            <div className={classes.calculatorResult}>
            
            <ul>
                <li>Monthly Loan EMI : <span className={classes.loanEmi} id="emi"></span></li>
                <li>Principle Amount : <span className={classes.loanPrinciple}id="loan_amountShow" ></span></li>
                <li>Loan on Interest : <span className={classes.loanInterestRate} id="interest"></span></li>
                <li>Total Amount to be Paid : <span className={classes.loanTotal}id="final_amount" ></span></li>
            </ul>
           </div>
           <div className={classes.main}>
            <div className={classes.l1}>
            <div className ={classes.logo3}><img src={image1} alt="logo"></img></div>
                <div className={classes.star1}><img  src={image2} alt="4.5 star"></img></div>
                <p className={classes.star2}>Rated 4.5/5 By Customers</p>
                
            </div>
            <div className={classes.r1}>
                <img src={image3} alt="image3"></img>
            </div>
           </div>
           </div>
           
        </div>
       </div>
    );

};
export default EmiHome;