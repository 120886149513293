import classes from "./CompanyProfile.module.css";
import image1 from "./Images/awards.jpg";

function CompanyProfile(){
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                <div className={classes.head}><h1>Company Profile</h1></div>
                <div className={classes.linehlder}><span className={classes.divBar}></span> </div>
                <div className={classes.paragraph}><p>Unity Finsolutionis a dynamic and dedicated professional’s team of experienced financial services. We are MUMBAI-Pune based Company we can offer Lowest EMI and Interest Rates. Unity Finsolutionwho set up a financial business consultancy Services starting from 2023 as Name was Unity Finsolutionto facilitate the residential commercial borrowing requirements of high net worth individuals. Unity Finsolutionis always trying to Resolve Financial Problem all real needy People as Individual as well as Businessmen.
Here we invite you for a safe, scheduled and sophisticated business loan in order to maintain a sustainable growth. We’re driven by information based lending, online application acceptance, best in industry interest rate and viability based financing.
</p></div>
            </div>
            <div className={classes.right}>
                <img src={image1}></img>
            </div>
        </div>

    );
};
export default CompanyProfile;