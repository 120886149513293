import classes from "./Header.module.css";
function Header (){
    return(
        
        <div className={classes.main}>
        <div className={classes.email} ><a href="mailto:unityfinsolution@gmail.com" className={classes.tdecor}><i className="fa-solid fa-envelope"></i> unityfinsolution@gmail.com</a></div>
        <div className={classes.mobile}><a href="tel:+91 8291405773" className={classes.tdecor}><i className="fa-solid fa-phone"></i> +91 8291405773</a></div>
        <div><div className={classes.icon}><a href="https://www.instagram.com/unityfinsolution/" target="_blank" ><i class="fa-brands fa-instagram" /></a>  <a href="https://www.facebook.com/unityfinsolution/" target="_blank" ><i class="fa-brands fa-facebook-f"></i></a> <a href="https://www.linkedin.com/in/unity-finsolution-64b3732a7/" target="_blank" ><i class="fa-brands fa-linkedin-in"></i></a></div>
        </div>
        </div>
        
        
    );
};
export default Header;