import Header from "../../Header";
import Navbar from "../../Navbar";
import classes from "./HomeLoan.module.css";
import image from "./asset/home_loan.jpg";
import { useState,useEffect } from 'react';
import './Acc.css';
import icon from './asset/next.png';
import Footer from "../../Footer";

function HomeLoan(){
    const [r1, setR1] = useState(true);
    const [r2, setR2] = useState(false);
    const [r3, setR3] = useState(false);
    const [r4, setR4] = useState(false);
    const [r5, setR5] = useState(false);

    const clkHnd1 = () => {
        setR1(true);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd2 = () => {
        setR1(false);
        setR2(true);
        setR3(false);
        setR4(false);
        setR5(false);
    };

    const clkHnd3 = () => {
        setR1(false);
        setR2(false);
        setR3(true);
        setR4(false);
        setR5(false);
    };

    const clkHnd4 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(true);
        setR5(false);
    };

    const clkHnd5 = () => {
        setR1(false);
        setR2(false);
        setR3(false);
        setR4(false);
        setR5(true);
    };

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return(
        <div className={classes.mainAll}>
            <Header/>
            <Navbar/>
            <div className={classes.bg}>
                <h1>Home Loan</h1>
            </div>
            <div className={classes.container}>
                <div className={classes.left}>
                    <h1>Introduction</h1>
                    <p>Unity Finsolution is one of the leading companies across India to offer Home Loans to prospective Home buyers. We at Unity Finsolution understand that buying a home and building it, is more or less a once a lifetime event and we pledge to make the process towards this event, a hassle free one. The rates at which we offer Home Loans will be one of the lowest you will find in the market, as we are associated with top notch leading banks and NBFCs.
                        <br></br>
                        However, the interest rate for home loan depends on a lot of factors like your total loan amount, your profession, maximum period of repayment etc.
                        <br></br>
                        Home loan is given to entities who are interested to purchase or to construct a house. It works as a medium for those who are not able to provide the full cost of that property. When a home loan is provided to the consumer there is an interest charge that has to be paid to the bank monthly. For the eligibility of the home loan, consumer has to provide a property which is equivalent to the value of the home loan. To specify, the property is mortgaged to the bank as a security till the repayment of the Home Loan is done. When the repayment of the home loan is done to that bank or the financial institution, then once again the deed will be transferred back to that respective client.
                        <br></br>A home loan can have fixed or a variable interest rate.
                    </p>
                </div>
                <div className={classes.right}>
                    <img src={image} alt=""></img>
                </div>
            </div>
            <div className='AccHold'>
            <div className='left'>
                <button className={r1 ? 'AccBtn1 AccBtn11' : 'AccBtn'} onClick={clkHnd1}>Eligibility</button>
                <button className={r2 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd2}>Home Loan For Salaried Individuals</button>
                <button className={r3 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd3}>Home Loan For  Professionals</button>
                <button className={r4 ? 'AccBtn1' : 'AccBtn'} onClick={clkHnd4}>Home Loan For  Businesspersons</button>
                
            </div>
            <div className='right'>

                {r1 && <div className='right1'>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Applicant should be a citizen of India</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Should be occupying his current place of residence for a minimum of 3 years in the city</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Applicants should atleast be 24 years of age</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Form-16 issued by present employer.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>If the applicant is self employed, he should be in the business for atleast 3 years, if he/she is salaried, he should have minimum 3 years of Work Experience.</p>
                    </div>
                    
                </div>}

                {r2 && <div className='right2'>
                <div className='accContainIn'>
                        
                        <p className='rightTxt'><b>documents Required : </b></p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Photo Applicant & Co-Applicant.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Proof of identity (PAN Card) or residence Applicant & Co-Applicant.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Latest salary slips.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Six months bank statement of salary account</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Form-16 issued by present employer.</p>
                    </div>
                </div>}

                {r3 && <div className='right3'>
                <div className='accContainIn'>
                        
                        <p className='rightTxt'><b>documents Required : </b></p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Photo Applicant & Co-Applicant.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Proof of identity (PAN Card) or residence Applicant & Co-Applicant.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Business and Office Proof.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Educational qualification certificates, degrees, diplomas, and other academic credentials.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>IT Returns of the three years preceding the one in which loan is applied for.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Bank statements of last one year.</p>
                    </div>
                </div>}

                {r4 && <div className='right3'>
                <div className='accContainIn'>
                        
                        <p className='rightTxt'><b>documents Required : </b></p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Photo Applicant & Co-Applicant.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Proof of identity (PAN Card) or residence Applicant & Co-Applicant.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Business and Office Proof</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Proof of existence of business.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Detailed business profile.</p>
                    </div>
                    <div className='accContainIn'>
                        <img className='rightIcon' src={icon} alt=''></img>
                        <p className='rightTxt'>Profit and loss sheet and balance sheets of the company for the three years.</p>
                    </div>
                </div>}

                
                
            </div>
        </div>
        <Footer/>
        </div>

    );
};
export default HomeLoan;