import Footer from "../../Footer";
import Header from "../../Header";
import Navbar from "../../Navbar";
import classes from "./CreditCard.module.css";
import image from "./asset/loan-against-credit-card.jpg";
import { useEffect } from "react";

function CreditCard(){
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return(
        <div className={classes.mainAll}>
            <Header/>
            <Navbar/>
            <div className={classes.bg}>
                <h1>Credit Card</h1>
            </div>
            <div className={classes.main}>
                <div className={classes.left}>
                    <h1>Introduction</h1>
                    <p>What is loan against credit card?A loan against credit card works like a personal loan. It is a means of financing that can help you meet your monetary needs in a relatively short period of time. As long as you have a credit card in your name, you can avail money against it, usually without having to submit any new documents.Credit card loans or loans against credit card are usually pre-approved and can be availed instantly.
                        <br></br>Unity Finsolution provides the best for credit card loan services. To avail one, call us today!
                    </p>
                </div>
                <div className={classes.right}>
                    <img src={image} alt="" />
                </div>
            </div>
            <Footer/>
        </div>

    );
};
export default CreditCard;
