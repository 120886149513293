import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import AboutCo from "../Components/AboutCo";
import WatsappFloat from "../Components/WatsappFloat";
import { useEffect } from "react";
import Profile from "../Components/Profile";



function About (){
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return(
        <>
        <Header/>
        <Navbar/>
        
       <AboutCo/>
       <Profile/>
       <WatsappFloat/>
        <Footer/>
        </>
    );
};
export default About;