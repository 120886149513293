import Footer from "../../Footer";
import Header from "../../Header";
import Navbar from "../../Navbar";
import classes from "./Doctor.module.css";
import image from "../../../Components/Images/young-doctor-getting-ready-work.jpg";
import { useEffect } from "react";

function Doctor(){
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return(
        <div className={classes.container1}>
            <Header/>
            <Navbar/>
            <div className={classes.bg}>
                <h1>Doctor and CA professional loans</h1>
            </div>
        <div className={classes.container}>
            <div className={classes.left}>
                <h1>Introduction</h1>
                <p>Are you a dedicated healer or meticulous financial wizard, making strides in your profession? At Unity Finsolution, we recognize the commitment and expertise that doctors and chartered accountants bring to their respective fields. Introducing our exclusive Doctor and CA Professional Loans – designed to support your financial aspirations and propel you towards unprecedented success!</p>
<div className={classes.key}>
    <h4>👩‍⚕️ Doctor Loans :</h4>
    <p><i class="fa-regular fa-hand-point-right"></i><b>Holistic Financing:</b> From setting up a new clinic to upgrading medical equipment, our Doctor Loans provide comprehensive financial solutions tailored to your unique needs.</p>
    <p><i class="fa-regular fa-hand-point-right"></i><b>Compassionate Interest Rates:</b> Benefit from competitive interest rates that reflect our commitment to supporting the healthcare community.</p>
    <p><i class="fa-regular fa-hand-point-right"></i><b>Swift Approvals:</b> Your time is valuable. Experience quick and hassle-free loan approvals to keep your focus where it matters – on your patients.</p>
    
</div>
<div className={classes.key}>
    <h4>🧑‍💼 CA Professional Loans:</h4>
    <p><i class="fa-regular fa-hand-point-right"></i><b> Strategic Financial Support: </b>  Empower your chartered accountancy practice with tailor-made financing options to seize new opportunities, invest in technology, or manage working capital effectively.</p>
    <p><i class="fa-regular fa-hand-point-right"></i><b> Tax-Optimized Solutions: </b> Our CA Professional Loans are structured to maximize tax benefits, providing you with a smart and efficient financial strategy.</p>
    <p><i class="fa-regular fa-hand-point-right"></i><b> Effortless Application Process::</b>A seamless and transparent application process that respects your time, with minimal documentation required.</p>
    
</div>
            </div>
            <div className={classes.right}>
                <img src={image} alt="" />
            </div>
        </div>
        <Footer/>
        </div>
    );
};
export default Doctor;