import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from './Images/UNITYLOGO.jpeg';

export default function WatsappFloat() {
    return (
        <div className="App">
            <FloatingWhatsApp
                phoneNumber=""
                accountName="UNITY"
                avatar={logo}
                chatMessage="Hello"
                allowEsc
                allowClickAway
                notification
                notificationSound
            />
        </div>
    );
}