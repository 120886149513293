import classes from "./ContactText.module.css";


function ContactText(){
    return(
        <div className={classes.container}>
            <div className={classes.bottom}>
           <div className={classes.row}>
           <div className={classes.card}>
                <h4>Address 1</h4>
                <p>Office No 304, 3 rd Floor,Jalaram Business Center, Pai Nagar , Ganjawalla Lane , Above Axis Bank Borivali West Mumbai.</p>
                <p>+91 7977732722 <br/> +91 8291405773</p>
            </div>
            <div className={classes.card}>
                <h4>Address 2</h4>
                <p>office no 203 2nd floor ashoka mall bund garden road near ranka jewellers pune 411001</p>
                <p>+91 7020494248 <br/> +91 9322689427 <br/>+91 8291405773</p>
            </div>
            
            <div className={classes.card}>
                <h4>Address 3</h4>
                <p>Office No -202,Ram Tirth Chs Ltd,Ram Mandir Road ,Babhai Naka,Borivali West 400092
Near Ram Mandir</p>
                <p>+91 7738222583 <br/> +91 8291405773</p>
            </div>
           </div>
           </div>
           </div>
    );
};
export default ContactText;